import React from 'react'
import './reason.css'
import image1 from "../../assets/image1.png"
import image2 from "../../assets/image2.png"
import image3 from "../../assets/image3.png"
import image4 from "../../assets/image4.png"
import nb from "../../assets/nb.png"
import adidas from "../../assets/adidas.png"
import nike from "../../assets/nike.png"
import tick from "../../assets/tick.png"

export default function reason() {
    return (
        <div className="Reason" id="reason">
            <div className="left-side">
                <img src={image1} alt=""></img>
                <img src={image2} alt=""></img>
                <img src={image3} alt=""></img>
                <img src={image4} alt=""></img>
            </div>

            <div className="right-side">
                <span>Something</span>

                <div>
                    <span className="stroke-text">Why </span>
                    <span>Choose Us ?</span>
                </div>

                <div className="details-r">
                    <div>
                        <img src={tick}></img>
                        <span>OVER 140+ EXPERT COACHS</span>

                    </div>
                    <div>
                        <img src={tick}></img>
                        <span>TRAIN SMARTER AND FASTER THAN BEFORE</span>

                    </div>
                    <div>
                        <img src={tick}></img>
                        <span>1 FREE PROGRAM FOR NEW MEMBER</span>

                    </div>
                    <div>
                        <img src={tick}></img>
                        <span>RELIABLE PARTNERS</span>

                    </div>
                </div>
                <span style={{
                    color: "var(--gray)",
                    fontWeight: "normal"
                }
                }>OUR PARTNERS</span>

                <div className="partners">
                    <img src={nb} alt=""></img>
                    <img src={nike} alt=""></img>
                    <img src={adidas} alt=""></img>
                </div>


            </div>
        </div>
    )
}
